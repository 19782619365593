import { ROUTER_PERMISSIONS } from 'src/app/authorization/router-permissions';
import languageDictionary from '../../transloco/default';
import { NestedPropertyOf } from '../../transloco/types/nested-key-of.type';

export interface INavigationItemDefinition {
    id: string;
    displayName: NestedPropertyOf<typeof languageDictionary>;
    tooltip: NestedPropertyOf<typeof languageDictionary>;
    routerLink: string;
    routerLinkOptions: {
        exact: boolean;
    };
    icon: string;
    permission: string;
    IsExpanded: boolean;
    subItems: INavigationItemDefinition[];
}

export const NavSideBarItems: INavigationItemDefinition[] = [
    {
        id: 'home',
        displayName: 'home.name',
        tooltip: 'home.name',
        routerLink: '/tenant',
        routerLinkOptions: { exact: true },
        icon: 'fa-home',
        permission: ROUTER_PERMISSIONS.tenant,
        IsExpanded: false,
        subItems: [],
    },
    {
        id: 'dashboards',
        displayName: 'dashboards.name',
        tooltip: 'dashboards.name',
        routerLink: '/dashboards/',
        routerLinkOptions: { exact: false },
        icon: 'fa-th',
        permission: ROUTER_PERMISSIONS.dashboards,
        IsExpanded: false,
        subItems: [],
    },
    {
        id: 'edge-devices',
        displayName: 'devices.name',
        tooltip: 'devices.name',
        routerLink: '/devices',
        routerLinkOptions: { exact: false },
        icon: 'fa-microchip',
        permission: ROUTER_PERMISSIONS.edgeDevice,
        IsExpanded: false,
        subItems: [],
    },
    {
        id: 'assets-overview',
        displayName: 'assets.name',
        tooltip: 'assets.name',
        routerLink: '/assets/',
        routerLinkOptions: { exact: true },
        icon: 'fa-plug',
        permission: ROUTER_PERMISSIONS.asset,
        IsExpanded: false,
        subItems: [],
    },
    {
        id: 'asset-groups-overview',
        displayName: 'assetGroups.name',
        tooltip: 'assetGroups.name',
        routerLink: '/asset-groups/',
        routerLinkOptions: { exact: false },
        icon: 'fa-object-group',
        permission: ROUTER_PERMISSIONS.asset,
        IsExpanded: false,
        subItems: [],
    },
    {
        id: 'workflows',
        displayName: 'workflows.name',
        tooltip: 'workflows.name',
        routerLink: null,
        routerLinkOptions: { exact: true },
        icon: 'fa-project-diagram',
        permission: '',
        IsExpanded: false,
        subItems: [
            {
                id: 'overview',
                displayName: 'workflows.overview.name',
                tooltip: 'workflows.overview.name',
                routerLink: '/workflows',
                routerLinkOptions: { exact: true },
                icon: 'fa-project-diagram',
                permission: ROUTER_PERMISSIONS.workflow,
                IsExpanded: false,
                subItems: [],
            },
            {
                id: 'modules',
                displayName: 'workflows.modules.name',
                tooltip: 'workflows.modules.name',
                routerLink: '/workflows/modules',
                routerLinkOptions: { exact: false },
                icon: 'fa-puzzle-piece',
                permission: ROUTER_PERMISSIONS.workflowModules,
                IsExpanded: false,
                subItems: [],
            },
        ],
    },
    {
        id: 'admin',
        displayName: 'administration.name',
        tooltip: 'administration.name',
        routerLink: null,
        routerLinkOptions: { exact: false },
        icon: 'fa-tasks',
        permission: '',
        IsExpanded: false,
        subItems: [
            {
                id: 'provision-edge-device',
                displayName: 'administration.provisioning.name',
                tooltip: 'administration.provisioning.name',
                routerLink: '/device/register',
                routerLinkOptions: {
                    exact: true,
                },
                icon: 'fa-server',
                permission: ROUTER_PERMISSIONS.provisionEdgeDevice,
                IsExpanded: false,
                subItems: [],
            },
            {
                id: 'tenant-customization',
                displayName: 'administration.tenantCustomization.name',
                tooltip: 'administration.tenantCustomization.description',
                routerLink: '/tenant/administration/customization',
                routerLinkOptions: {
                    exact: true,
                },
                icon: 'fa-cogs',
                permission: ROUTER_PERMISSIONS.tenantCustomization,
                IsExpanded: false,
                subItems: [],
            },
            {
                id: 'group-administration',
                displayName: 'administration.groupAdministration.name',
                tooltip: 'administration.groupAdministration.description',
                routerLink: '/tenant/administration/groups',
                routerLinkOptions: {
                    exact: false,
                },
                icon: 'fa-users',
                permission: ROUTER_PERMISSIONS.groupAdministration,
                IsExpanded: false,
                subItems: [],
            },
            {
                id: 'user-management',
                displayName: 'administration.userManagement.name',
                tooltip: 'administration.userManagement.description',
                routerLink: '/tenant/administration/usermanagement',
                routerLinkOptions: {
                    exact: false,
                },
                icon: 'fa-user',
                permission: ROUTER_PERMISSIONS.userManagement,
                IsExpanded: false,
                subItems: [],
            },
            {
                id: 'audit-history',
                displayName: 'administration.auditHistory.name',
                tooltip: 'administration.auditHistory.description',
                routerLink: '/tenant/administration/auditHistory',
                routerLinkOptions: {
                    exact: false,
                },
                icon: 'fa-history',
                permission: ROUTER_PERMISSIONS.auditHistory,
                IsExpanded: false,
                subItems: [],
            },
        ],
    },
    {
        id: 'data-export',
        displayName: 'dataExport.name',
        tooltip: 'dataExport.name',
        routerLink: '/export',
        routerLinkOptions: { exact: true },
        icon: 'fa-file-export',
        permission: ROUTER_PERMISSIONS.dataExport,
        IsExpanded: false,
        subItems: [],
    },
    {
        id: 'alerts',
        displayName: 'alerts.name',
        tooltip: 'alerts.name',
        routerLink: '/alerts',
        routerLinkOptions: { exact: true },
        icon: 'fa-bell',
        permission: ROUTER_PERMISSIONS.alert,
        IsExpanded: false,
        subItems: [],
    },
    {
        id: 'global-admin',
        displayName: 'globalAdministration.name',
        tooltip: 'globalAdministration.name',
        routerLink: null,
        routerLinkOptions: { exact: true },
        icon: 'fa-user-cog',
        permission: '',
        IsExpanded: false,
        subItems: [
            {
                id: 'tenants',
                displayName: 'globalAdministration.tenants.name',
                tooltip: 'globalAdministration.tenants.name',
                routerLink: '/admin/tenants',
                routerLinkOptions: { exact: true },
                icon: 'fa-house-user',
                permission: ROUTER_PERMISSIONS.tenants,
                IsExpanded: false,
                subItems: [],
            },
            {
                id: 'remote-maintenance',
                displayName: 'globalAdministration.remoteMaintenance.name',
                tooltip: 'globalAdministration.remoteMaintenance.name',
                routerLink: '/admin/remoteMaintenance',
                routerLinkOptions: {
                    exact: false,
                },
                icon: 'fa-desktop',
                permission: ROUTER_PERMISSIONS.remoteMaintenance,
                IsExpanded: false,
                subItems: [],
            },
        ],
    },
];
